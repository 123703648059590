<template>
  <div
    class="partners"
    v-if="section?.sponsors?.length"
  >
    <div class="container">
      <h2
        class="title title__h2 align--center margin__b--24"
        v-if="translate(section.title)"
      >
        {{ translate(section.title) }}
      </h2>

      <ul class="sponsors">
        <li
          class="sponsors__li"
          v-for="({ link, logo }, index) in section.sponsors"
          :key="`sponsor_${index}`"
        >
          <a
            :href="translate(link)"
            v-if="translate(link)"
            class="sponsors__link"
          >
            <img
              :src="translate(logo)"
              class="sponsors__logo"
            >
          </a>

          <img
            :src="translate(logo)"
            class="sponsors__logo"
            v-else
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))

defineProps({
  section: { type: Object, required: true }
})

const { translate } = useI18n()
</script>

<style lang="scss" scoped>
.title {
  font-family: $font-family--secondary;
  color: #4C5562;
  position: relative;
  z-index: 10;

  &__h2 {
    font-weight: 700;
    font-size: rem(28px);
    line-height: 1.208;

    @include mq(md) {
      font-size: rem(32px);
    }
  }
}

.partners {
  padding: 30px 0;
}

.sponsors {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 32px;

  @include mq(md) {
    gap: 0 32px;
  }

  &__li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 175px;

    @include mq(md) {
      height: 170px;
      width: 200px;
    }
  }

  &__link {
    display: block;
    transition: all .2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &__logo {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 90px;
  }
}
</style>