<template>
  <div
    class="benefiting bg__color--white"
    v-if="selectedProject"
  >
    <section
      class="banner"
      v-if="isVisible('banner')"
    >
      <div class="container align--center">
        <app-link
          class="back margin__b--6"
          :link="banner.cta"
          v-slot="{ text }"
        >
          <fa-icon
            class="margin__r--6 back__icon"
            :icon="['fal', 'long-arrow-left']"
          />

          {{ text }}
        </app-link>

        <h1
          class="title title__h1 bold"
          v-if="translate(banner.title)"
        >
          {{ translate(banner.title) }}
        </h1>
      </div>
    </section>

    <section
      class="stats"
      v-if="isVisible('stats')"
    >
      <div class="container stats__container align--center">
        <ol
          class="metrics"
          v-if="statistics"
        >
          <li
            class="metrics__li"
            v-for="(metric, key) in stats.metrics"
            :key="`metric_${key}`"
          >
            <strong class="metrics__value bold">
              <app-date
                v-if="metric.type === 'date' && metric.date"
                :timestamp="metric.date"
                filter="differenceInCalendarDays"
              />

              <app-money
                v-else-if="metric.type === 'amount_collected'"
                :amount="statistics[metric.type] || 0"
              />

              <template v-else-if="metric.type === 'custom'">
                {{ translate(metric.value) || 0 }}
              </template>

              <template v-else>
                {{ statistics[metric.type] || 0 }}
              </template>
            </strong>

            <span class="d-block margin__t--18">
              {{ translate(metric.text) }}
            </span>
          </li>
        </ol>

        <ul class="links">
          <li
            class="links__li"
            v-if="translate(stats?.links?.project)"
          >
            <router-link
              :to="{ name: 'Project', params: { projectSlug: selectedProject.slug } }"
              class="btn btn__size--full-12 d-block btn__color--prim"
              :aria-label="selectedProject.name"
            >
              {{ translate(stats?.links?.project) }}
            </router-link>
          </li>

          <li
            class="links__li"
            v-if="stats?.links?.campaigns"
          >
            <app-link
              :link="stats?.links?.campaigns"
              :query="{ 'actions_data[project][benefiting]': benefitingId }"
              class="btn btn__size--full-12 d-block btn__solid--prim"
            />
          </li>
        </ul>
      </div>
    </section>

    <section-about
      :section="about"
      button-type="link"
      v-if="isVisible('about')"
    />

    <section
      class="actions"
      v-if="isVisible('actions') && projects.length"
    >
      <div class="container align--center">
        <h2
          class="title title__h2 margin__b--24"
          v-if="translate(actions.title)"
        >
          {{ translate(actions.title) }}
        </h2>

        <div class="row align--left justify-content-center">
          <template
            v-for="project in projects"
            :key="project.id"
          >
            <section class="col-12 col-sm-6 col-md-3">
              <project-card
                class="project-card"
                :project="project"
              />
            </section>
          </template>
        </div>

        <app-link
          class="btn btn__color--prim btn__size--classic d-inline-block margin__t--24"
          :link="actions.cta"
          :query="{ benefiting_id: benefitingId }"
        />
      </div>
    </section>

    <section-partners
      :section="partners"
      v-if="isVisible('partners')"
    />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useProjectStore } from '@/stores/ProjectStore'
import useSection from '@/composables/modules/pages/useSection'
import { useI18n } from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'
import { omit as _omit, get as _get, first as _first } from 'lodash'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppDate = defineAsyncComponent(() => import('&/atoms/AppDate'))
const ProjectCard = defineAsyncComponent(() => import('&/organisms/ProjectCard'))
const SectionAbout = defineAsyncComponent(() => import('&/modules/pages/beplanet/SectionAboutBeplanet'))
const SectionPartners = defineAsyncComponent(() => import('&/modules/pages/beplanet/SectionPartnersBeplanet'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate } = useI18n()
const { getSectionAttributes, isVisible } = useSection(props.page)
const { getStatistics } = useClientStore()
const { getProjects, getProject } = useProjectStore()

const settings = computed(() => getSectionAttributes('settings'))
const banner = computed(() => getSectionAttributes('banner'))
const stats = computed(() => getSectionAttributes('stats'))
const about = computed(() => getSectionAttributes('about'))
const actions = computed(() => getSectionAttributes('actions'))
const partners = computed(() => getSectionAttributes('partners'))

const projectId = computed(() => _first(settings.value?.project_id))
const { project: selectedProject } = getProject({ id: projectId.value, dependencies: [projectId] })

const benefitingId = computed(() => _get(selectedProject.value, 'benefiting.id'))

const projectsQuery = computed(() => {
  const query = _get(actions.value, 'query', {})
  const sortByField = _get(projects_overview_options.find(sort => sort.value === query.sort), 'query', { 'sort_by_field[field]': 'created_at','sort_by_field[direction]': 'desc' })

  return _omit({ ...query, ...sortByField, benefiting_id: [benefitingId.value] }, ['sort', 'collapse'])
})

const { statistics } = getStatistics({ query: computed(() => ({ ..._omit(stats.value?.query, 'collapse'), benefiting_id: [benefitingId.value] })), dependencies: [isVisible('stats'), !!stats.value?.metrics?.length, benefitingId] })
const { projects } = getProjects({ query: projectsQuery, dependencies: [isVisible('actions'), benefitingId] })
</script>

<style lang="scss" scoped>
$color--primary: #93C01F;
$color--secondary: #3D500C;
$color--tertiary: #F3FFD3;

.no-thumb {
  font-size: rem(72px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}


:deep() {
  .btn {
    line-height: 1.18;

    &__size {
      &--classic {
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 30px;
        font-size: rem(18px);

        @include mq(md) {
          padding-top: 16px;
          padding-bottom: 16px;
          font-size: rem(20px);
        }
      }

      &--full-12 {
        border-radius: 100px;
        border-width: 2px;
        font-size: rem(22px);

        @include mq(sm) {
          padding: 16px 35px;
          border-width: 4px;
          font-size: rem(24px);
        }

        @include mq(md) {
          font-size: rem(32px);
        }
      }
    }

    &__solid {
      &--prim {
        background: $white;
      }
    }
  }
}

.title {
  font-family: $font-family--secondary;
  color: #4C5562;
  position: relative;
  z-index: 10;

  &__h1 {
    font-weight: 700;
    font-size: rem(32px);
    line-height: 1.208;

    @include mq(md) {
      font-size: rem(48px);
    }
  }

  &__h2 {
    font-weight: 700;
    font-size: rem(28px);
    line-height: 1.208;

    @include mq(md) {
      font-size: rem(32px);
    }
  }
}

.benefiting {
  color: #979CA4;
  font-size: rem(18px);
  line-height: 1.208;

  & > *:last-child {
    padding-bottom: 60px;
  }
}

.banner {
  padding: 24px 0;

  :deep(.back) {
    display: block;
    color: #979CA4;
    transition: all .3s ease-in-out;

    .back__icon {
      transition: all .3s ease-in-out;
      color: #979CA4;
    }

    &:hover {
      color: $color--primary;

      .back__icon {
        transform: translateX(-10px);
        color: $color--primary;
      }
    }
  }
}

.stats {
  padding-bottom: 50px;

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    @include mq(md) {
      grid-template-columns: auto 360px;
    }

    @include mq(lg) {
      gap: 50px;
    }
  }

  .metrics {
    gap: 32px;
    display: grid;
    grid-template-columns: 1fr;
    font-size: rem(24px);
    line-height: 0.75;
    color: $color--secondary;
    background: $color--tertiary;
    border-radius: 20px;
    padding: 48px 12px;

    @include mq(sm) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 24px;
    }

    @include mq(md) {
      padding: 110px 12px;
    }

    &__li {
      flex: 1;
    }

    &__value {
      font-size: rem(50px);
      line-height: 0.3;
      white-space: nowrap;

      @include mq(lg) {
        font-size: rem(80px);
      }
    }
  }

  .links {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }
}

.actions {
  padding: 30px 0;
}
</style>