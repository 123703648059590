import Block from '@/classes/procedures/Block'

export default class Blocks extends Array {
  constructor (blocks, ...rest) {
    Array.isArray(blocks) ? super(...blocks.map(block => new Block(block))) : super(...rest)
  }

  get transaction_form_amount () {
    return this.get('actions_data.transaction.form_amount')
  }

  get transaction_payment_method () {
    return this.get('actions_data.transaction.payment_method')
  }

  get transaction_frequency () {
    return this.get('actions_data.transaction.frequency')
  }

  get order_lines () {
    return this.all('actions_data.order.order_lines')
  }

  get product_selection () {
    return this.all('product_selection')
  }

  get project_products () {
    return this.get('project_products')
  }

  get product_ids () {
    return this.order_lines.map(block => block.attributes.ids || []).flat()
  }

  get (key) {
    return this.find(block => block.name === key || block.model === key)
  }

  all (key) {
    return this.filter(block => block.name?.includes(key) || block.model?.includes(key))
  }

  contains (key) {
    return this.some(block => block.name?.includes(key) || block.model?.includes(key))
  }

  getAvailableBlocks (data) {
    return this.filter(block => block.isAvailable(data))
  }

  getUnvisibleModels (data) {
    const omitted_models = ['actions_data.order', 'customer.firstname', 'customer.lastname', 'customer.email']
    return this.filter(block => !block.isVisible(data) && block.model && !omitted_models.some(model => block.model.includes(model))).map(block => block.model)
  }
}
