<template>
  <footer class="footer bg__color--white">
    <div class="container footer__container">
      <div class="footer__col footer__col--logo">
        <router-link
          class="logo"
          :to="{ name: 'Home', params: { locale } }"
        >
          <img
            class="logo__img"
            :src="translate(column_1.logo) || client.logo.small.url"
            :alt="client.name"
          >
        </router-link>

        <app-link
          class="website"
          :link="column_1.website"
          v-slot="{ text }"
        >
          <fa-icon
            class="margin__r--6 website__icon"
            :icon="['fal', 'long-arrow-left']"
          />

          {{ text }}
        </app-link>
      </div>

      <div>
        <nav
          class="navigation align--center"
          v-if="column_2.links?.length"
        >
          <ul class="navigation__ul">
            <li
              v-for="({ link }, index) in column_2.links"
              :key="`navigation_${index}`"
              class="navigation__li"
            >
              <app-link
                :link="link"
                class="navigation__a link--naked"
              />
            </li>
          </ul>
        </nav>
      </div>

      <div>
        <h4
          class="footer__title"
          v-if="translate(column_3.title)"
        >
          {{ translate(column_3.title) }}
        </h4>

        <ul
          class="sponsors"
          v-if="column_3.sponsors?.length"
        >
          <template
            v-for="({logo, link}, key) in column_3.sponsors"
            :key="`sponsor_${key}`"
          >
            <li
              class="sponsors__li"
              v-if="translate(logo)"
            >
              <a
                :href="translate(link)"
                v-if="translate(link)"
                class="sponsors__link"
              >
                <img
                  :src="translate(logo)"
                  class="sponsors__logo"
                >
              </a>

              <img
                :src="translate(logo)"
                class="sponsors__logo"
                v-else
              >
            </li>
          </template>
        </ul>
      </div>

      <div class="footer__col footer__col--actions">
        <app-link
          :link="column_4.cta"
          class="bold btn btn__color--prim btn__size--classic d-inline-block"
        />

        <ul
          class="networks margin__t--24"
          v-if="socialNetworks.length"
        >
          <li
            class="networks__li"
            v-for="(network, index) in socialNetworks"
            :key="'network_' + index"
          >
            <a
              class="networks__a"
              :href="translate(network.url)"
              target="_blank"
              :aria-label="translate(network.text)"
            >
              <fa-icon
                v-if="network.icon"
                size="lg"
                :icon="network.icon"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

import AppLink from '&/atoms/AppLink'

const props = defineProps({ section: { type: Object, required: true } })

const { getClient } = useClientStore()
const { translate } = useI18n()
const { locale } = toRefs(useI18n())

const { client } = getClient()

const column_1 = computed(() => _get(props, 'section.attributes.column_1', {}))
const column_2 = computed(() => _get(props, 'section.attributes.column_2', {}))
const column_3 = computed(() => _get(props, 'section.attributes.column_3', {}))
const column_4 = computed(() => _get(props, 'section.attributes.column_4', {}))

const socialNetworks = computed(() => _get(client.value, 'platform.social_networks', []).filter(network => translate(network.url)).map(network => {
  if (network.icon[1] === 'facebook-square') network.icon[1] = 'facebook-f'
  if (network.icon[1] === 'linkedin') network.icon[1] = 'linkedin-in'

  return network
}))
</script>

<style lang="scss" scoped>
$color--primary: #93C01F;
$color--secondary: #3D500C;
$color--tertiary: #F3FFD3;

:deep() {
  .btn {
    line-height: 1.18;

    &__size {
      &--classic {
        padding: 12px 48px;
        border-radius: 30px;
        font-size: rem(18px);

        @include mq(md) {
          padding: 16px 48px;
          font-size: rem(20px);
        }
      }
    }
  }
}

.footer {
  border-top: 1px solid #E9E9E9;
  color: #4C5562;
  font-size: rem(18px);
  padding: 30px 0;

  @include mq(md) {
    padding-bottom: 67px;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 36px;
    align-items: center;

    @include mq(sm) {
      gap: 24px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(md) {
      gap: 50px;
      grid-template-columns: auto 1fr 1.5fr auto;
    }
  }

  &__col {
    &--logo {
      text-align: center;

      @include mq(md) {
        text-align: left;
      }
    }

    &--actions {
      text-align: center;

      @include mq(md) {
        text-align: right;
      }
    }
  }

  &__title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px;
  }
}

.logo {
  display: inline-block;

  &__img {
    display: block;
    width: auto;
    height: 82px;
  }
}

:deep(.website) {
  display: block;
  color: #4C5562;
  transition: all .3s ease-in-out;
  margin-top: 12px;

  @include mq(md) {
    margin-top: 36px;
  }

  .website__icon {
    transition: all .3s ease-in-out;
    color: #4C5562;
  }

  &:hover {
    color: $color--primary;

    .website__icon {
      transform: translateX(-10px);
      color: $color--primary;
    }
  }
}

.navigation {
  line-height: 1.22;

  &__li {
    &:not(:last-child) {
      margin-bottom: 6px;

      @include mq(md) {
        margin-bottom: 24px;
      }
    }
  }

  :deep(.navigation__a) {
    color: #4C5562;
    transition: all .3s ease-in-out;
    display: block;

    &:hover,
    &.is-active {
      color: $color--primary;
    }
  }
}

.sponsors {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @include mq(sm) {
    gap: 33px 12px;
  }

  &__li {
    display: flex;
    align-items: center;
  }

  &__link {
    display: block;
    transition: all .2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &__logo {
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 82px;
  }
}

.networks {
  display: flex;
  justify-content: center;
  gap: 11px;
  font-size: rem(16px);

  @include mq(md) {
    justify-content: flex-end;
  }

  &__a {
    display: block;
    color: $color--primary;
    border: 2px solid $color--primary;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s all ease-in-out;

    &:hover {
      color: shade(hsl(77, 72%, 44%));
    }
  }
}
</style>