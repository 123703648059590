<template>
  <div>
    <payment-provider-tamaro
      :payment-methods="paymentMethods"
      :v="validator.block"
      v-if="paymentProvider === 'tamaro'"
    />

    <template v-else>
      <div class="payment">
        <input-radio
          class="payment__radio"
          v-model="model"
          :v="validator.block"
          :data="paymentMethods"
        />
      </div>

      <app-component-loader
        v-show="component"
        :component-path="component"
      />
    </template>
  </div>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent, inject } from 'vue'
import { useModule } from '@/composables/app/useModule'
import { useProcedure, useProcedureTransaction, useProcedureBlockValidator } from '@/composables/modules/procedures'
import { useTracking } from '@/vendors/tracking'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'
import { whenever } from '@/utils/utils'

import InputRadio from '&/atoms/InputRadio'

const AppComponentLoader = defineAsyncComponent(() => import('&/atoms/AppComponentLoader'))
const PaymentProviderTamaro = defineAsyncComponent(() => import('&/modules/procedures/fragments/transaction/PaymentProviderTamaro'))

const props = defineProps({ block: { type: Object, required: true } })

const { block } = toRefs(props)

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')

const tracking = useTracking()
const { t } = useI18n()
const { model } = useModule({ component: block, parent: procedure_execution })
const { paymentProvider } = useProcedure({ procedure })
const { frequency } = useProcedureTransaction({ procedure, procedure_execution })
const { validator } = useProcedureBlockValidator({ validations: { block: block.value.validations }, state: { block: model } })

const defaultPaymentMethods = computed(() => _get(procedure.value, 'payment_profile.data.payment_methods', []).map(method => formatOptions(method)))
const selectedPaymentMethods = computed(() => _get(procedure.value, `form_configuration.payment_methods.${frequency.value}`, []).filter(method => method.enabled === true).map(method => formatOptions(method.name)))
const paymentMethods = computed(() => selectedPaymentMethods.value.length ? selectedPaymentMethods.value : defaultPaymentMethods.value)

const component = computed(() => {
  if (['stripe', 'stripe_standard_connect'].includes(paymentProvider.value) && model.value === 'three_d_secure') return 'modules/procedures/fragments/transaction/PaymentMethodStripe3dSecure'
  if (model.value === 'sofort') return 'modules/procedures/fragments/transaction/PaymentMethodSofort'
  return null
})

const formatOptions = option => ({ label: t(`$payment.${option.toLowerCase()}`), value: option })

whenever(model, () => tracking.procedure.addPaymentInfo({ procedure_execution: procedure_execution.value, procedure: procedure.value }))
</script>

<style lang="scss" scoped>
.payment {
  &__radio {
    flex: 1;

    & :deep() .radio__label {
      padding: calc($padding__base / 2) $padding__base;
      border-radius: $radius__cards;
      justify-content: space-between;
      cursor: pointer;
    }

    & :deep() .radio--selected {
      background-color: $color--secondary;
      color: v(text-button-secondary-color);

      .radio__icon {
        color: v(text-button-secondary-color);
      }
    }
  }
}
</style>
