<template>
  <div>
    <h3
      class="title color--lg"
      v-if="isShowed(`text_${text}`) && title"
    >
      {{ title }}
    </h3>

    <p
      class="date bold"
      v-if="isShowed(value)"
    >
      <app-date
        :timestamp="date"
        :filter="format"
      />
    </p>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { get as _get } from 'lodash'

const AppDate = defineAsyncComponent(() => import('&/atoms/AppDate'))

const props = defineProps({ block: { type: Object, required: true } })

const { translate } = useI18n()
const { objects, settings, isShowed, getText } = useBlock(props.block)

const { text, value, format = 'dd/MM/yyyy – HH:mm' } = settings

const project = computed(() => _get(objects.value, 'project'))

const procedure = computed(() => _get(project.value, 'procedures.donation'))
const title = computed(() => translate(getText(text)))
const date = computed(() => new Date(_get(procedure.value, value)))
</script>

<style lang="scss" scoped>
.title {
  font-size: rem(14px);
}

.date {
  font-size: rem(20px);
  margin-top: 3px;
}
</style>