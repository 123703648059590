<template>
  <div>
    <ul
      class="products"
      :class="{ 'products--large': !column.is_small }"
      v-if="isShowed('products_list')"
    >
      <li
        class="card card--loading"
        v-if="loader && !products.length"
      >
        <app-spinner />
      </li>

      <li
        v-for="product in products"
        v-else
        :key="`product_${product.id}`"
      >
        <product-card
          :product="product"
          :quantity="getQuantity(product.id)"
          @update-quantity="quantity => updateOrder({ product, quantity })"
          :layout="column.is_small ? 'compact' : 'normal'"
          :is-loading="loader"
        />
      </li>
    </ul>

    <div
      class="purchase"
      :class="{ 'purchase--large': !column.is_small }"
      v-if="isShowed('text_order') && labelButton && products.length"
    >
      <project-button
        class="purchase__button"
        :class="{ 'btn__size--full-6': column.is_small, 'btn__size--medium': !column.is_small }"
        :project="project"
        :is-preview="isPreview"
      >
        {{ labelButton }}

        <fa-icon
          class="icon margin__l--6"
          :icon="['fal', 'basket-shopping-simple']"
          fixed-width
        />
      </project-button>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed } from 'vue'
import { useProcedureExecutionStore } from '@/stores/ProcedureExecutionStore'
import { useProductStore } from '@/stores/ProductStore'
import { useProcedure, useProcedureOrder } from '@/composables/modules/procedures'
import { useI18n } from '@/vendors/i18n'
import { useBlock } from '@/composables/modules/templates'
import { whenever } from '@/utils/utils'
import { get as _get } from 'lodash'

const AppSpinner = defineAsyncComponent(() => import('&/atoms/AppSpinner'))
const ProductCard = defineAsyncComponent(() => import('&/organisms/ProductCard'))
const ProjectButton = defineAsyncComponent(() => import('@/components/modules/project/ProjectButton'))

const props = defineProps({
  column: { type: Object, required: true },
  block: { type: Object, required: true }
})

const { translate } = useI18n()

const { objects, isShowed, getText, isPreview } = useBlock(props.block)
const { getProducts } = useProductStore()
const { getProcedureExecution } = useProcedureExecutionStore()
const { products: allProducts, loader } = getProducts({ query: computed(() => ({ project_id: _get(objects.value, 'project.id'), display_on_page: true })), reload: true })

const products = computed(() => allProducts.value.filter(product => product.display_options !== 'never'))
const project = computed(() => _get(objects.value, 'project'))
const procedure = computed(() => _get(project.value, 'procedures.donation'))

const { id } = useProcedure({ procedure })
const { procedureExecution } = getProcedureExecution({ procedure_id: id })
const { initOrder, getQuantity, updateOrder } = useProcedureOrder({ procedure, procedure_execution: procedureExecution, products })

const labelButton = computed(() => translate(getText('order')))

whenever(products, () => initOrder())
</script>

<style lang="scss" scoped>
.products {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;

  &--large {
    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.purchase {
  margin-top: 24px;

  &--large {
    padding-top: 24px;
    border-top: 1px solid rgba(#2E293D, .1);

    .purchase__button {
      width: 100%;

      @include mq(sm) {
        width: auto;
        min-width: 260px;
      }
    }
  }

  &__button {
    justify-content: center;
    border-radius: 4px;
    display: inline-block;

    .icon {
      order: 2;
    }
  }
}

.card {
  &--loading {
    height: 415px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
