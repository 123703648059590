<template>
  <div class="home bg__color--white">
    <section
      class="banner"
      v-if="isVisible('banner')"
    >
      <template v-if="banner.type === 'video'">
        <div class="banner__video">
          <video
            autoplay
            playsinline
            loop
            preload
          >
            <source
              :src="translate(banner.video.url)"
              type="video/mp4"
            >
          </video>
        </div>
      </template>

      <slider
        :slides="banner.slides"
        class="banner__slider"
        v-else-if="banner.slides.length"
      />

      <div class="container banner__container">
        <app-link
          class="banner__partner d-inline-block"
          :link="banner.partner?.link"
          v-if="translate(banner.partner?.logo)"
        >
          <img
            class="banner__logo"
            :src="translate(banner.partner.logo)"
            :alt="text"
          >
        </app-link>
      </div>
    </section>

    <section
      class="campaign"
      v-if="isVisible('campaign')"
    >
      <div class="container campaign__container">
        <div class="campaign__text">
          <div
            class="editor__content margin__t--12 banner__text"
            v-html="translate(campaign.text)"
            v-if="translate(campaign.text)"
          />
        </div>

        <div class="campaign__actions align--center">
          <h2
            class="title title__h2 margin__b--24"
            v-if="translate(campaign.title)"
          >
            {{ translate(campaign.title) }}
          </h2>

          <ul
            class="links"
            v-if="campaign.links?.length"
          >
            <li
              class="links__li"
              v-for="({ link }, index) in campaign.links"
              :key="`campaign_link_${index}`"
            >
              <app-link
                :link="link"
                class="btn btn__size--full-12 d-block"
                :class="{'btn__color--prim': index % 2 === 0, 'btn__solid--prim': index % 2 !== 0 }"
              />
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section
      class="stats"
      v-if="isVisible('stats')"
    >
      <div class="container stats__container">
        <h2
          class="title title__h2 align--center stats__title"
          v-if="translate(stats.title)"
        >
          {{ translate(stats.title) }}
        </h2>

        <div class="stats__map">
          <figure class="stats__figure">
            <img
              class="stats__image"
              :src="translate(stats.image)"
              :alt="translate(stats.title)"
              width="624"
              height="479"
              v-if="translate(stats.image)"
            >

            <fa-icon
              class="no-thumb"
              :icon="['far', 'image']"
              v-else
            />
          </figure>
        </div>

        <div class="stats__metrics align--center">
          <ol
            class="metrics"
            v-if="stats.metrics?.length && statistics"
          >
            <li
              class="metrics__li"
              v-for="(metric, key) in stats.metrics"
              :key="`metric_${key}`"
            >
              <strong class="metrics__value">
                <app-date
                  v-if="metric.type === 'date' && metric.date"
                  :timestamp="metric.date"
                  filter="differenceInCalendarDays"
                />

                <app-money
                  v-else-if="metric.type === 'amount_collected'"
                  :amount="statistics[metric.type] || 0"
                />

                <template v-else-if="metric.type === 'custom'">
                  {{ translate(metric.value) || 0 }}
                </template>

                <template v-else>
                  {{ statistics[metric.type] || 0 }}
                </template>
              </strong>

              <span class="d-block margin__t--18">
                {{ translate(metric.text) }}
              </span>
            </li>
          </ol>

          <app-link
            :link="stats.cta"
            class="margin__t--48 btn btn__color--prim btn__size--classic bold"
          />
        </div>
      </div>
    </section>

    <section
      class="news"
      v-if="isVisible('news') && news.items?.length"
    >
      <div class="container">
        <h2
          class="title title__h2 align--center margin__b--24"
          v-if="translate(news.title)"
        >
          {{ translate(news.title) }}
        </h2>

        <ul class="articles">
          <li
            class="articles__li bg__color--white"
            v-for="({ image, title, date, location, text, cta}, index) in news.items"
            :key="`news_${index}`"
          >
            <article class="article">
              <figure class="article__figure">
                <img
                  :src="translate(image)"
                  :alt="translate(title)"
                  width="408"
                  height="235"
                  class="article__image"
                  v-if="translate(image)"
                >

                <fa-icon
                  class="no-thumb"
                  :icon="['far', 'image']"
                  v-else
                />
              </figure>

              <div class="article__content padding--30">
                <div>
                  <h3
                    class="title title__h3 article__title"
                    v-if="translate(title)"
                  >
                    {{ translate(title) }}
                  </h3>

                  <ul
                    class="article__info margin__t--6"
                    v-if="translate(date) || translate(location)"
                  >
                    <li v-if="translate(date)">
                      <fa-icon
                        class="margin__r--6"
                        :icon="['fal', 'calendar-days']"
                      />

                      <app-date
                        :timestamp="date"
                        filter="dd/MM/yyyy"
                      />
                    </li>

                    <li v-if="translate(location)">
                      <fa-icon
                        class="margin__r--6"
                        :icon="['fal', 'location-dot']"
                      />

                      {{ translate(location) }}
                    </li>
                  </ul>

                  <div
                    class="editor__content margin__t--18"
                    v-html="translate(text)"
                    v-if="translate(text)"
                  />
                </div>

                <app-link
                  :link="cta"
                  class="link__color--prim article__link"
                />
              </div>
            </article>
          </li>
        </ul>
      </div>
    </section>

    <section-about
      :section="about"
      v-if="isVisible('about')"
    />

    <section-partners
      :section="partners"
      v-if="isVisible('partners')"
    />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import useSection from '@/composables/modules/pages/useSection'
import { useI18n } from '@/vendors/i18n'
import { omit as _omit } from 'lodash'

const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppMoney = defineAsyncComponent(() => import('&/atoms/AppMoney'))
const AppDate = defineAsyncComponent(() => import('&/atoms/AppDate'))
const Slider = defineAsyncComponent(() => import('&/modules/pages/beplanet/HomepageBeplanetSlider'))
const SectionAbout = defineAsyncComponent(() => import('&/modules/pages/beplanet/SectionAboutBeplanet'))
const SectionPartners = defineAsyncComponent(() => import('&/modules/pages/beplanet/SectionPartnersBeplanet'))

const props = defineProps({
  page: { type: Object, required: true },
  sections: { type: Object, required: true }
})

const { translate } = useI18n()
const { getSectionAttributes, isVisible } = useSection(props.page)
const { getStatistics } = useClientStore()

const banner = computed(() => getSectionAttributes('banner'))
const campaign = computed(() => getSectionAttributes('campaign'))
const stats = computed(() => getSectionAttributes('stats'))
const news = computed(() => getSectionAttributes('news'))
const about = computed(() => getSectionAttributes('about'))
const partners = computed(() => getSectionAttributes('partners'))

const { statistics } = getStatistics({ query: _omit(stats.value?.query, 'collapse'), dependencies: [isVisible('stats'), !!stats.value?.metrics?.length] })
</script>

<style lang="scss" scoped>
$color--primary: #93C01F;
$color--secondary: #3D500C;
$color--tertiary: #F3FFD3;

.no-thumb {
  font-size: rem(72px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}


:deep() {
  .btn {
    line-height: 1.18;

    &__size {
      &--classic {
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 30px;
        font-size: rem(18px);

        @include mq(md) {
          padding-top: 16px;
          padding-bottom: 16px;
          font-size: rem(20px);
        }
      }

      &--full-12 {
        border-radius: 100px;
        border-width: 2px;
        font-size: rem(22px);

        @include mq(sm) {
          padding: 16px 35px;
          border-width: 4px;
          font-size: rem(24px);
        }

        @include mq(md) {
          font-size: rem(32px);
        }
      }
    }

    &__solid {
      &--prim {
        background: $white;
      }
    }
  }
}

.title {
  font-family: $font-family--secondary;
  color: #4C5562;
  position: relative;
  z-index: 10;

  &__h2 {
    font-weight: 700;
    font-size: rem(28px);
    line-height: 1.208;

    @include mq(md) {
      font-size: rem(32px);
    }
  }

  &__h3 {
    font-weight: 700;
    font-size: rem(24px);
    line-height: 1.22;
  }
}

.home {
  color: #979CA4;
  font-size: rem(18px);
  line-height: 1.208;

  @include mq(md) {
    font-size: rem(24px);
  }

  & > *:last-child {
    padding-bottom: 60px;
  }
}

.banner {
  position: relative;
  background: $color--tertiary;
  overflow: hidden;
  min-height: 300px;

  @include aspect-ratio(1440, 432);

  & + .news.news {
    margin-top: 0;
  }

  &__video {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    video {
      min-width: 100%;
      min-height: 100%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__slider {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
    height: 32px;
    height: 0;
    display: flex;
    align-items: flex-end;

    @include mq(md) {
      height: 48px;
    }
  }

  :deep(.banner__partner) {
    transition: all .2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &__logo {
    display: block;
    width: auto;
    height: 32px;

    @include mq(md) {
      height: 48px;
    }
  }
}

.campaign {
  padding-bottom: 30px;

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    @include mq(sm) {
      grid-template-columns: auto 300px
    }

    @include mq(md) {
      grid-template-columns: auto 410px;
    }
  }

  &__text {
    padding-top: 24px;

    @include mq(sm) {
      padding-top: 48px;
    }

    :deep() {
      h1, h2 {
        font-family: $font-family--secondary;
        color: #4C5562;
        font-weight: 700;
        font-size: rem(28px);
        line-height: 1.208;
        margin: 0;

        @include mq(md) {
          font-size: rem(32px);
        }
      }
    }
  }

  &__actions {
    position: relative;
    background: $color--tertiary;
    padding: 40px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include mq(sm) {
      padding: 80px 24px;
    }

    @include mq(md) {
      padding: 80px 24px 120px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100vw;
      height: 100%;
      background: $color--tertiary;
      left: 50%;
      transform: translateX(-50%);
      top: 0;

      @include mq(sm) {
        display: none;
      }
    }

    .links {
      position: relative;
      z-index: 10;
      width: 100%;

      &__li {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
  }
}

.stats {
  padding: 30px 0;

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    align-items: center;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__map {
    @include mq(sm) {
      grid-column-start: 1;
    }
  }

  &__figure {
    position: relative;
    background: darken(#F7F7F7, 10%);

    @include aspect-ratio(624, 479);
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: auto;
  }

  &__metrics {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include mq(sm) {
      padding: 24px 0;
    }
  }

  .metrics {
    font-size: rem(18px);
    line-height: 0.75;
    color: $color--secondary;

    @include mq(md) {
      font-size: rem(24px);
    }

    &__li {
      &:not(:last-child) {
        margin-bottom: 12px;

        @include mq(md) {
          margin-bottom: 24px;
        }
      }
    }

    &__value {
      display: block;
      font-weight: 700;
      color: $color--primary;
      font-size: 2.5em;
      line-height: 0.666;

      @include mq(md) {
        font-size: 3em;
      }
    }
  }
}

.news {
  font-size: rem(18px);
  line-height: 1.38;
  background: #F7F7F7;
  padding: 60px 0;
  margin-bottom: 30px;

  &:not(:first-child) {
    margin: 30px 0;
  }

  .articles {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    @include mq(sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(lg) {
      grid-template-columns: repeat(6, 1fr);
    }

    &__li {
      @include mq(sm) {
        grid-column: span 2;

        &:last-child:nth-child(2n - 1) {
          grid-column-end: -2;
        }
      }

      @include mq(lg) {
        &:last-child:nth-child(2n - 1) {
          grid-column-end: inherit;
        }

        &:last-child:nth-child(3n - 1) {
          grid-column-end: -2;
        }

        &:nth-last-child(2):nth-child(3n + 1) {
          grid-column-end: 4;
        }

        &:last-child:nth-child(3n - 2) {
          grid-column-end: 5;
        }
      }
    }
  }

  .article {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #E9E9E9;
    position: relative;

    &__figure {
      position: relative;
      background: darken(#F7F7F7, 10%);

      @include aspect-ratio(408, 235);
    }

    &__image {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: auto;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1 auto;
      gap: 18px;
    }

    &__title {
      color: $color--primary;
    }

    &__info {
      display: flex;
      gap: 24px;
    }

    :deep(.article__link) {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 999;
      }
    }
  }
}
</style>