<template>
  <div>
    <hr class="separator separator__size--full border__color--dw margin__tb--18">

    <label
      class="input__label"
      for="country"
    >
      {{ t('$block_payment.sofort_country_label') }} *
    </label>

    <input-select-country
      class="margin__t--6"
      id="country"
      v-model="country"
      :placeholder="t('$block_payment.sofort_country_placeholder')"
      :allowed="countries"
      :v="validator"
    />
  </div>
</template>

<script setup>
import { computed, inject, onBeforeUnmount } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useProcedureTransaction, useProcedureExecution, useProcedureBlockValidator } from '@/composables/modules/procedures'
import { required } from '@vuelidate/validators'
import { get as _get } from 'lodash'

import InputSelectCountry from '&/atoms/InputSelectCountry'

const procedure = inject('procedure')
const procedure_execution = inject('procedure_execution')

const countries = ['AT', 'BE', 'DE', 'ES', 'IT', 'NL']

const country = computed({
  get: () => _get(paymentMethodInformations.value, 'sofort.country', null),
  set: country => paymentMethodInformations.value = country ? { sofort: { country } } : {}
})

const { t } = useI18n()
const { paymentMethodInformations } = useProcedureTransaction({ procedure, procedure_execution })
const { paymentMethod } = useProcedureExecution({ procedure, procedure_execution })
const { validator } = useProcedureBlockValidator({ validations: { required }, state: country })

const defaultCountry = computed(() => _get(procedure_execution.value, 'customer.address.country', null))

if (!country.value && countries.includes(defaultCountry.value)) country.value = defaultCountry.value

onBeforeUnmount(() => {
  if (paymentMethod.value !== 'sofort') country.value = null
})
</script>