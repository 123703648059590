import { inject } from 'vue'
import store from '@/store'
import * as SentryVue from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { get as _get } from 'lodash'

class Sentry {
  constructor () {
    this.enable = false
  }

  init ({ app, enable = false, dsn, router, environment = 'production' } = {}) {
    this.enable = enable

    if (!this.enable) return

    const client = _get(store.getters, 'client/client', {})

    SentryVue.init({
      app,
      dsn,
      integrations: [
        new BrowserTracing({ routingInstrumentation: SentryVue.vueRouterInstrumentation(router) })
      ],
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured'
      ],
      denyUrls: ['http://localhost:3000'],
      initialScope: {
        client: { id: client.id, name: client.name, allowed_features: client.allowed_features, active_languages: client.active_languages, active_currencies: client.active_currencies }
      },
      tracesSampleRate: 0.5,
      environment
    })
  }

  message (message, data = {}) {
    if (!this.enable) return

    let message_id
    const userId = _get(store, 'getters.auth/user.id')

    SentryVue.configureScope(scope => {
      Object.keys(data)
        .forEach(key => scope.setExtra(key, JSON.stringify(data[key])))

      if (userId)
        scope.setUser({ id: userId })

      message_id = SentryVue.captureMessage(message, 'info')
    })

    return message_id
  }

  error (exception, data = {}) {
    if (!this.enable) return

    let error_id
    const userId = _get(store, 'getters.auth/user.id')

    SentryVue.configureScope(scope => {
      Object.keys(data)
        .forEach(key => scope.setExtra(key, JSON.stringify(data[key])))

      if (userId)
        scope.setUser({ id: userId })

      error_id = SentryVue.captureException(exception)
    })

    return error_id
  }
}

const sentrySymbol = Symbol()
const sentry = new Sentry()

export const createSentry = {
  install: (app, config = {}) => {
    sentry.init({ app, ...config })

    app.provide(sentrySymbol, sentry)
  }
}

export function useSentry () {
  return inject(sentrySymbol)
}

export default sentry